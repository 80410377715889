import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  get,
  filter,
  uniqBy,
  find,
  toLower,
  has,
  mapKeys,
  values,
  replace,
} from "lodash";
//union
import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Checkbox from "@material-ui/core/Checkbox";
import { Box, Text, Image, Flex, Card } from "rebass";
import { getPhoto } from "./utils";
// import { Switch } from "@material-ui/core";
import { Loading, useQuery } from "../../../../components";
import { Checkbox, Icon, IconButton } from "@material-ui/core";
// import { Currency } from "../../../../components";

export const placeholder =
  "https://playitright.s3-ap-southeast-1.amazonaws.com/assets/1/content/ImageTextView-image-mobile-H9I.jpeg";

const getPhotosList = function (photos) {
  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    let listnew = [];
    mapKeys(photoSet, function (v, k) {
      listnew.push({ name: k + "", photos: v.photos });
      return { name: k + "" };
    });

    return listnew;
  } else if (has(photos, "*")) {
    var starPhoto = get(photos, "*");
    const sp = values(starPhoto);
    let listnew = [];
    mapKeys(sp, function (v, k) {
      listnew.push({ name: k + "", photos: v.photos });
      return { name: k + "" };
    });

    return listnew;
  }

  return null;
};

const getColorName = (options) => {
  const myFilter = find(options, function (fil) {
    const myName = get(fil, "name", "");
    const cm = toLower(myName);
    return cm === "colors" || cm === "color";
  });
  return myFilter?.values;
};

const ViewVariant = (props) => {
  const { product, onSelectSkuVariant, selectedSKUVariant } = props;
  const variantOptions = get(product, "variant.options", []);
  const variants = get(product, "variant.variants", {});

  const listPhotos = getPhotosList(get(product, "photos", {}));
  const myColor = getColorName(variantOptions);

  return (
    <Box p={[2]}>
      <Flex flexDirection={"row"} flexWrap={"wrap"}>
        {myColor &&
          myColor.length > 0 &&
          myColor.map((col, index) => {
            const photoView = find(listPhotos, function (o) {
              return o.name === col;
            });
            const myPhoto = get(photoView, "photos[0]", placeholder);
            const photoItem = replace(myPhoto, "?$zoom$", "");
            const mySKU = filter(variants, function (o) {
              return o.code === col;
            });
            const myvariantSKU = mySKU && mySKU.length > 0 ? mySKU[0].sku : "";
            const mySlug = `${product.slug};${myvariantSKU}`;
            const selectedSKUVariantku = find(selectedSKUVariant, function (o) {
              return o.sku === mySlug;
            });

            const isSelected =
              selectedSKUVariantku && selectedSKUVariantku?.sku === mySlug
                ? true
                : false;
            return (
              <Card
                key={index}
                style={{
                  backgroundColor: "#dddcdc",
                  borderRadius: 4,
                  padding: 0,
                  paddingBottom: 10,
                  position: "relative",
                }}
              >
                <Box
                  m={[1]}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    width: 80,
                  }}
                >
                  <Image
                    src={photoItem}
                    alt=''
                    style={{ width: 80, height: "auto" }}
                    onError={(e) => {
                      if (e.target.src !== placeholder) {
                        e.target.onerror = null;
                        e.target.src = placeholder;
                      }
                    }}
                  />
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    style={{
                      zIndex: 3,
                      position: "absolute",
                      paddingTop: 4,
                      top: 0,
                      right: 0,
                    }}
                  >
                    <Checkbox
                      checked={isSelected}
                      onChange={(event) =>
                        onSelectSkuVariant(event.target.checked, `${mySlug}`)
                      }
                      inputProps={{ "aria-label": "select all desserts" }}
                      color={"primary"}
                      style={{
                        padding: 2,
                        backgroundColor: "#d4d4d4",
                        borderRadius: 0,
                      }}
                    />
                  </Flex>

                  <div style={{ textAlign: "left", paddingLeft: 4 }}>{col}</div>
                  <div
                    style={{
                      textAlign: "left",
                      paddingLeft: 4,
                      fontSize: 10,
                      lineHeight: "normal",
                    }}
                  >
                    {myvariantSKU}
                  </div>
                </Box>
              </Card>
            );
          })}
      </Flex>
    </Box>
  );
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    product,
    classes,
    isSelected,
    selectedSKUVariant,
    onSelectSkuVariant,
  } = props;
  const productItem = useQuery(
    `/products/${encodeURIComponent(product?.slug)}`
  );

  const onClickSelect = (checked) => {
    onSelectAllClick(checked);
  };

  const onClickSelectVariantEmpty = (checked, productSlug) => {
    onSelectSkuVariant(checked, productSlug);
    onSelectAllClick(false);
  };

  if (productItem.loading) {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            colSpan={3}
            component='th'
            scope='row'
            className={classes.cell}
          >
            <Loading />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const myImage = getPhoto(product.photos);
  const productData = get(productItem, "data", {});
  const variant = get(productData, "variant", null);
  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={3}
          component='th'
          scope='row'
          className={classes.cell}
        >
          <Box width={[1]}>
            <Flex width={[1]} flexDirection={"row"} alignItems='center'>
              {variant === null ? (
                <Checkbox
                  onChange={(event) =>
                    onClickSelectVariantEmpty(
                      event.target.checked,
                      productData.slug
                    )
                  }
                  inputProps={{ "aria-label": "select all desserts" }}
                  color={"primary"}
                />
              ) : (
                <IconButton
                  color='primary'
                  aria-label='extanded'
                  onClick={() =>
                    onClickSelect(
                      !(isSelected && isSelected.slug === product.slug)
                    )
                  }
                >
                  {isSelected && isSelected.slug === product.slug ? (
                    <Icon>expand_more</Icon>
                  ) : (
                    <Icon>chevron_right</Icon>
                  )}
                </IconButton>
              )}
              {myImage && myImage !== "" && (
                <Image
                  ml={[3]}
                  src={getPhoto(productData.photos)}
                  style={{ width: 50, height: "auto" }}
                  onError={(e) => {
                    if (e.target.src !== placeholder) {
                      e.target.onerror = null;
                      e.target.src = placeholder;
                    }
                  }}
                />
              )}
              <Text pl={[3]}>{get(productData, "name", "")}</Text>
              {get(productData, "slug", "") !== "" && (
                <a
                  target={"_blank"}
                  href={`https://www.playitright.com/${get(
                    productData,
                    "slug",
                    ""
                  )}`}
                  rel='noopener noreferrer'
                >
                  <Text pl={[3]} style={{ color: "#1890ff" }}>
                    {get(productData, "slug", "")}
                  </Text>
                </a>
              )}
            </Flex>
            {isSelected && isSelected.slug === productData.slug && (
              <ViewVariant
                product={productData}
                selectedSKUVariant={selectedSKUVariant}
                onSelectSkuVariant={onSelectSkuVariant}
              />
            )}
          </Box>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 400,
  },
  cell: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}));

export default function TableProduct(props) {
  const {
    product,
    selectedProduct,
    setSelectedProduct,
    selectedSKUVariant,
    setSelectedSKUVariant,
  } = props;
  //translate,values
  // const variants = get(product, "variant.variants", []);
  const classes = useStyles();

  // const [selected, setSelected] = React.useState(values);
  const handleSelectAllClick = (checked) => {
    if (checked) {
      const selectedProductA = selectedProduct;
      selectedProductA.push({ slug: product.slug });
      const myUniq = uniqBy(selectedProductA, "slug");
      setSelectedProduct(myUniq);

      return;
    }
    const newSelected = filter(selectedProduct, function (o) {
      return o.slug !== product.slug;
    });
    setSelectedProduct(newSelected);
  };

  const handleSelectVariantSKU = (checked, myProductSKU) => {
    if (checked) {
      const selectedProductA = selectedSKUVariant;
      selectedProductA.push({ sku: myProductSKU });
      const myUniq = uniqBy(selectedSKUVariant, "sku");
      setSelectedSKUVariant(myUniq);
      return;
    }
    const newSelected = filter(selectedSKUVariant, function (o) {
      return o.sku !== myProductSKU;
    });
    setSelectedSKUVariant(newSelected);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   const newValues = newSelected.map((item) => {
  //     return { id: product.id, sku: item };
  //   });
  //   setSelected(newSelected);
  //   let newSelectedProduct = [];
  //   newSelectedProduct = filter(selectedProduct, function (o) {
  //     return o.id !== product.id;
  //   });
  //   const newUnion = union(newValues, newSelectedProduct);
  //   setSelectedProduct(newUnion);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const isSelected = find(selectedProduct, function (o) {
    return o.slug === product.slug;
  });

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            onSelectAllClick={handleSelectAllClick}
            onSelectSkuVariant={handleSelectVariantSKU}
            selectedSKUVariant={selectedSKUVariant}
            isSelected={isSelected}
            product={product}
            classes={classes}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
