import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Flex, Box } from "rebass";
import { get, concat, uniqBy, without } from "lodash";
//uniq,
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { withDataProvider, Loading } from "react-admin";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  styles,
} from "../../promotions/components/promotions/DialogFields";
import TableProduct from "../../promotions/components/promotions/TableProduct";
import { EmptyList } from "../../../components";

const DialogProducts = (props) => {
  const { translate, classes, dataProvider, formData } = props;
  const productSelected = get(formData, `products`, []);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const myProductsList = productSelected.map((item) => {
    return { slug: item };
  });
  const myProductsListSKU = productSelected.map((item) => {
    return { sku: item };
  });
  const [selected, setSelected] = useState(myProductsList);
  const [selectedSKUVariant, setSelectedSKUVariant] =
    useState(myProductsListSKU);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (search && search.length > 3) {
        //remove => active: true  12/08/2023
        dataProvider
          .getList("products", {
            pagination: { page: 1, perPage: 30 },
            sort: { field: "name", order: "ASC" },
            filter: { search: search },
          })
          .then((res) => {
            const { data } = res;
            // console.log("datap-1000", data.length);
            if (data) {
              setProducts(data);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log("error");
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [search, dataProvider]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };

  const onSaveProduct = () => {
    // console.log(selected);
    // console.log("productSelected", productSelected);
    if (myProductsListSKU && myProductsListSKU.length > 0) {
      // const newConcat = concat(myProductsList, selected);
      const newConcat = concat(myProductsListSKU, selectedSKUVariant);
      const myList = uniqBy(newConcat, "sku");
      const myProducts = myList.map((item) => {
        // return item.id;
        return item?.sku;
      });
      props.updateField(`products`, without(myProducts, undefined));
    } else {
      const myList = uniqBy(selectedSKUVariant, "sku");
      const myProducts = myList.map((item) => {
        // return item.id;
        return item?.sku;
      });
      props.updateField(`products`, without(myProducts, undefined));
    }

    setOpen(false);
    setSearch("");
    setSelectedSKUVariant([]);
    setSelected([]);
  };

  return (
    <div>
      <Button
        color='primary'
        onClick={handleClickOpen}
        className={classes.button}
        startIcon={<AddCircleOutlineIcon />}
      >
        {translate("resources.buttons.add_product")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {translate("resources.buttons.add_product")}
        </DialogTitle>
        <DialogContent dividers>
          <Flex width={[1]} flexDirection={"column"}>
            <Flex
              width={[1]}
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <Box
                width={[1]}
                p={[2]}
                style={{
                  backgroundColor: "#FFFFFF",
                  position: "absolute",
                  zIndex: 2,
                }}
              >
                <FormControl
                  fullWidth
                  variant='outlined'
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <TextField
                    id='standard-search'
                    label=''
                    variant={"outlined"}
                    style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    // onChange={onSearch}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type='search'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Box>
            </Flex>
            {loading ? (
              <Box width={[1]} p={[2]}>
                <Loading />
              </Box>
            ) : (
              <Box width={[1]} p={[2]} mt={["70px"]}>
                {products.length > 0 ? (
                  products.map((product) => {
                    // let newValues = [];
                    // selected.map((item) => {
                    //   if (item.id === product.id) {
                    //     console.log(product.id, product.slug);
                    //     newValues.push(item.sku);
                    //   }
                    //   return "";
                    // });
                    return (
                      <TableProduct
                        key={product.id}
                        {...props}
                        product={product}
                        selectedProduct={selected}
                        setSelectedProduct={setSelected}
                        selectedSKUVariant={selectedSKUVariant}
                        setSelectedSKUVariant={setSelectedSKUVariant}
                        // values={newValues}
                      />
                    );
                  })
                ) : (
                  <Box width={[1]}>
                    <EmptyList message={"resources.errors.no_have_product"} />
                  </Box>
                )}
              </Box>
            )}
          </Flex>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate("resources.buttons.cancel")}
          </Button>
          <Button
            autoFocus
            onClick={onSaveProduct}
            color='primary'
            variant='contained'
          >
            {translate("resources.buttons.add")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(withDataProvider(DialogProducts));
